import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import Masthead from "../components/strapi/structure/Masthead"
import Twocol from "../components/strapi/structure/TwoCol"
import Healthbenefits from "../components/strapi/health-benefits/Healthbenefits"
import Outropiggy from "../components/strapi/structure/Outropiggy"

export default function PartsService({ data }) {
  return (
    <Layout>
      <SEO title="Health & Benefits" />

      {data.allStrapiHealthBenefits.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.hb_mh.mh_title}
            mhsubtitle={node.hb_mh.mh_subtitle}
            mhbackground={node.hb_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.hb_benefits.txtimg_title}
            description={node.hb_benefits.txtimg_description}
            blueTitle={node.hb_benefits.txtimg_bluetitle}
            blueLink={node.hb_benefits.txtimg_bluelink}
            bgColor={node.hb_benefits.txtimg_bgcolor}
            order={node.hb_benefits.txtimg_order}
            rowimg={node.hb_benefits.txtimg_img.publicURL}
          />
          <Healthbenefits />
          <Outropiggy outro={node.hb_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query HealthBenefits {
    allStrapiHealthBenefits {
      edges {
        node {
          id # id of the node
          hb_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          hb_benefits {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          hb_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`
